import React from 'react'
import { useSelector } from 'react-redux'

import { notificationsSelector, NotificationsState, NOTIFICATION_TRANSITION_SECONDS } from '../../hasura/slices/notifications'
import { palette } from '../../lib/cssHelpers'

export default function Notification() {
  const { notification, notificationOpacity }: NotificationsState = useSelector(notificationsSelector)

  return (
    <p
      className="position-fixed m-0 text-white px-3 py-2 rounded pe-none user-select-none"
      style={{
        bottom: '50px',
        left: '50%',
        transform: 'translate(-50%)',
        opacity: notificationOpacity,
        transitionDuration: `${NOTIFICATION_TRANSITION_SECONDS}s`,
        transitionProperty: 'opacity',
        backgroundColor: notification?.color || palette.success,
        zIndex: 9999,
      }}
    >
      {notification?.title}
    </p>
  )
}
