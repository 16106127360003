import React, { useEffect, useRef, useState } from 'react'
import compact from 'lodash/compact'
import moment from 'moment'
import orderBy from 'lodash/orderBy'
import sumBy from 'lodash/sumBy'
import uniq from 'lodash/uniq'
import { useDispatch, useSelector } from 'react-redux'

import { consultationsSelector, ConsultationsState } from '../../hasura/slices/consultations'
import { fetchVetsExpandedAction, usersSelector, UsersState } from '../../hasura/slices/users'
import { pluralize, commaSeparatedString, cleanDisplayName, vetImage, isSuperAdmin } from '../../lib/helpers'

// @ts-ignore
import message from '../../lib/images/message.png'
// @ts-ignore
import slackIds from '../../../slackIds.json'

const READER_COMMUNITY_CHANNEL_ID = 'C02DAUUTNNQ'
const READER_DIRECT_MESSAGE_BASE = 'slack://user?team=T01KF3AFB4N&id='

interface Props {
  irxMode?: boolean
}

const MEDALS_HASH: any = {
  0: ' 🥇',
  1: ' 🥈',
  2: ' 🥉',
}

export function VetStatus(props: Props) {
  const dispatch = useDispatch()

  const [displayDropdown, setDisplayDropdown] = useState(false)
  const [hoveringVetId, setHoveringVetId] = useState<string | undefined>()

  const { accessToken, vetStatus, vetsExpanded, role, user, isRadimalEnterprise }: UsersState = useSelector(usersSelector)
  const { allRequestedConsultations }: ConsultationsState = useSelector(consultationsSelector)

  const fetchVetsExpandedInterval = useRef<any | null>(null)

  const fetchVetsExpanded = () => {
    if (!accessToken || !user) return

    dispatch(fetchVetsExpandedAction(accessToken, user.organization.enterprise.id))
  }

  useEffect(() => {
    if (!accessToken || !user) return

    clearInterval(fetchVetsExpandedInterval.current)
    fetchVetsExpandedInterval.current = setInterval(fetchVetsExpanded, 60 * 1000)
    fetchVetsExpanded()

    return () => {
      clearInterval(fetchVetsExpandedInterval.current)
    }
  }, [accessToken, user])

  const online = vetsExpanded.filter((v) => vetStatus?.online_vet_ids.includes(v.id))

  const monthlyOrdered = orderBy(vetsExpanded, ['monthly_total_consults.aggregate.count', 'display_name'], ['desc', 'asc'])
  const dailyLabelsOrdered = orderBy(vetsExpanded, ['daily_total_labels.aggregate.count', 'display_name'], ['desc', 'asc'])
  const monthlyLabelsOrdered = orderBy(vetsExpanded, ['monthly_total_labels.aggregate.count', 'display_name'], ['desc', 'asc'])

  if (!vetsExpanded.length) {
    return null
  }

  return (
    <div
      onMouseOver={() => setDisplayDropdown(true)}
      onMouseLeave={() => setDisplayDropdown(false)}
      style={{ pointerEvents: 'auto' }}
      className="position-relative ml-4 z-100"
    >
      <div className="flex-center py-2">
        <div className="d-flex">
          {online.map((o, idx) => {
            const statOn = vetStatus?.stat_plus_vet_ids.includes(o.id)

            return (
              <img
                style={{ objectFit: 'cover', borderRadius: '50%', padding: '2px' }}
                key={idx}
                className={`icon-m mx-1 border ${statOn ? 'border-success' : ''}`}
                src={vetImage(o)}
                referrerPolicy="no-referrer"
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null
                  currentTarget.src = 'https://radimal-images.s3.us-east-2.amazonaws.com/logo.png'
                }}
              />
            )
          })}
        </div>

        <p className={`single-line mb-0 ml-1 text-m ${props.irxMode ? 'text-dark-bg' : 'gray8'}`}>
          {pluralize('specialist', online.length)} online
        </p>
      </div>

      {displayDropdown && (
        <div style={{ top: '100%', minWidth: '600px' }} className="p-3 position-absolute bg--white border rounded left-0">
          <div className="d-flex justify-content-between">
            <div style={{ flex: 4 }} />

            <h6 style={{ flex: 4 }} className="flex-center text-s bold mb-0 text--gray7">
              Daily
            </h6>

            <h6 style={{ flex: 4 }} className="flex-center text-s bold mb-0 text--gray7">
              Monthly
            </h6>

            <div className="flex-even" />
          </div>

          <div>
            {orderBy(
              vetsExpanded,
              ['daily_total_consults.aggregate.count', 'monthly_total_consults.aggregate.count', 'display_name'],
              ['desc', 'desc', 'asc']
            ).map((v, idx) => {
              const isOnline = vetStatus?.online_vet_ids.includes(v.id)
              const isRecentlyOnboarded = moment(v.created_at).isAfter(moment().subtract(14, 'day'))
              const statOn = vetStatus?.stat_plus_vet_ids.includes(v.id)

              const dailyMedal = (v.daily_total_consults.aggregate?.count || 0) > 0 && idx <= 2 ? MEDALS_HASH[idx] : ''

              const dailyLabelsIndex = dailyLabelsOrdered.findIndex((m) => m.id === v.id)
              const monthlyIndex = monthlyOrdered.findIndex((m) => m.id === v.id)
              const monthlyLabelsIndex = monthlyLabelsOrdered.findIndex((m) => m.id === v.id)

              const monthlyMedal =
                (v.monthly_total_consults.aggregate?.count || 0) > 0 && monthlyIndex <= 2 ? MEDALS_HASH[monthlyIndex] : ''
              const dailyLabelsMedal =
                (v.daily_total_labels.aggregate?.count || 0) > 0 && dailyLabelsIndex <= 2 ? MEDALS_HASH[dailyLabelsIndex] : ''
              const monthlyLabelsMedal =
                (v.monthly_total_labels.aggregate?.count || 0) > 0 && monthlyLabelsIndex <= 2 ? MEDALS_HASH[monthlyLabelsIndex] : ''

              const slackId = slackIds[v.id]

              const claimed = commaSeparatedString(
                uniq(
                  compact(
                    vetStatus?.locked_consultations
                      .filter((l) => l.vet_id === v.id)
                      .map((l) => allRequestedConsultations.find((a) => a.id === l.consultation_id)?.case.patient.display_name)
                  )
                ).sort()
              )

              if (
                !v.last_30_days_total_consults.aggregate?.count &&
                !isOnline &&
                !isRecentlyOnboarded &&
                !v.monthly_total_labels.aggregate?.count
              ) {
                return null
              }

              return (
                <div
                  onMouseEnter={() => setHoveringVetId(v.id)}
                  onMouseLeave={() => setHoveringVetId(undefined)}
                  className="my-2"
                  key={idx}
                >
                  <div className="flex-center">
                    <div style={{ flex: 4, opacity: isOnline ? 1 : 0.5 }} className="d-flex align-items-center">
                      <img
                        style={{ objectFit: 'cover', borderRadius: '50%' }}
                        key={idx}
                        className="icon-s"
                        src={vetImage(v)}
                        referrerPolicy="no-referrer"
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null
                          currentTarget.src = 'https://radimal-images.s3.us-east-2.amazonaws.com/dot.png'
                        }}
                      />

                      <p className="text-s m-0 ml-2">
                        {cleanDisplayName(v.display_name)}

                        {statOn && <span className="ml-1 text--success bold">STAT+</span>}
                      </p>
                    </div>

                    <p style={{ flex: isRadimalEnterprise ? 2 : 4, opacity: isOnline ? 1 : 0.5 }} className="text-center m-0 text-s">
                      {v.daily_total_consults.aggregate?.count} {dailyMedal}
                    </p>

                    {isRadimalEnterprise && (
                      <p style={{ flex: 2, opacity: isOnline ? 1 : 0.5 }} className="text-center m-0 text-s">
                        {v.daily_total_labels.aggregate?.count} {dailyLabelsMedal}
                      </p>
                    )}

                    <p style={{ flex: isRadimalEnterprise ? 2 : 4, opacity: isOnline ? 1 : 0.5 }} className="text-center m-0 text-s">
                      {v.monthly_total_consults.aggregate?.count} {monthlyMedal}
                    </p>

                    {isRadimalEnterprise && (
                      <p style={{ flex: 2, opacity: isOnline ? 1 : 0.5 }} className="text-center m-0 text-s">
                        {v.monthly_total_labels.aggregate?.count} {monthlyLabelsMedal}
                      </p>
                    )}

                    <div className="flex-even d-flex justify-content-end">
                      {slackId && (
                        <img
                          style={{ opacity: hoveringVetId === v.id ? 1 : 0.5 }}
                          role="button"
                          className="icon-s transition-m"
                          src={message}
                          onClick={() => window.open(`${READER_DIRECT_MESSAGE_BASE}${slackId}`, '_blank')}
                        />
                      )}
                    </div>
                  </div>

                  {claimed && (
                    <p style={{ margin: '-5px 0 0 33px', width: '40%' }} className="text-xxs text--gray5 font-italic">
                      claimed {claimed}
                    </p>
                  )}

                  {isSuperAdmin(role) && v.incomplete_training_iterations.length > 0 && (
                    <p style={{ margin: '-5px 0 0 33px', width: '40%' }} className="text-xxs text--gray5 font-italic">
                      assigned {commaSeparatedString(v.incomplete_training_iterations.map((v) => v.condition.display_name))}
                    </p>
                  )}
                </div>
              )
            })}
          </div>

          <div className="d-flex justify-content-between">
            <div style={{ flex: 4 }} />

            <p style={{ flex: isRadimalEnterprise ? 2 : 4 }} className="text-center nowrap text-s mb-0">
              {pluralize(
                'case',
                sumBy(vetsExpanded, (v) => v.daily_total_consults.aggregate?.count || 0)
              )}
            </p>

            {isRadimalEnterprise && (
              <p style={{ flex: 2 }} className="text-center nowrap text-s mb-0">
                {pluralize(
                  'label',
                  sumBy(vetsExpanded, (v) => v.daily_total_labels.aggregate?.count || 0)
                )}
              </p>
            )}

            <p style={{ flex: isRadimalEnterprise ? 2 : 4 }} className="text-center nowrap text-s mb-0">
              {pluralize(
                'case',
                sumBy(vetsExpanded, (v) => v.monthly_total_consults.aggregate?.count || 0)
              )}
            </p>

            {isRadimalEnterprise && (
              <p style={{ flex: 2 }} className="text-center nowrap text-s mb-0">
                {pluralize(
                  'label',
                  sumBy(vetsExpanded, (v) => v.monthly_total_labels.aggregate?.count || 0)
                )}
              </p>
            )}

            <div className="flex-even" />
          </div>

          {isRadimalEnterprise && (
            <a
              target="_blank"
              href={`https://slack.com/app_redirect?channel=${READER_COMMUNITY_CHANNEL_ID}`}
              className="text-link text-s m-0 mt-2"
            >
              Reader Community
            </a>
          )}
        </div>
      )}
    </div>
  )
}
