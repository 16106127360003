import React from 'react'
import { Link } from 'gatsby'
import { useDispatch, useSelector } from 'react-redux'
import { useIntercom } from 'react-use-intercom'

import Divider from './common/Divider'
import { UsersState, usersSelector, setDisplayReferralModalAction, setNavOpenAction } from '../hasura/slices/users'
import { displayEnableBillingModalAction } from '../hasura/slices/users'
import { getWindow, isAdmin, isVet, isUser, isEnterpriseAdmin, isSuperAdmin } from '../lib/helpers'

// @ts-ignore
import expandIcon from '../lib/images/expand.svg'

interface Props {
  billingEnabled: boolean
  logout: () => void
  dark?: boolean
}

enum Route {
  Billing = 'Billing',
  Cases = 'Cases',
  Conditions = 'Conditions',
  DicomServers = 'DICOM Servers',
  Earnings = 'Earnings',
  Enterprises = 'Enterprises',
  PayRates = 'Pay Rates',
  Feed = 'Feed',
  Invoices = 'Invoices',
  Metrics = 'Metrics',
  Practices = 'Practices',
  Pricing = 'Pricing',
  Sales = 'Sales',
  Settings = 'Settings',
  Staff = 'Staff',
}

export function Nav(props: Props) {
  const dispatch = useDispatch()
  const { trackEvent } = useIntercom()

  const { user, role, navOpen, isRadimalEnterprise }: UsersState = useSelector(usersSelector)

  const pathname: string | undefined = getWindow(['location', 'pathname'])

  const link = (route: Route) => {
    const path = route.toLowerCase().replace(/ /g, '-')
    const isSelected = pathname?.replace(/\//g, '') === path

    return (
      <Link
        className={`single-line ${isSelected ? 'text--primary' : 'text--gray4'}`}
        key={route}
        style={{ textDecoration: 'none', outline: 'none' }}
        to={`/${path}`}
      >
        <h5 role="button" className="bold text-l hover-primary m-0 py-1">
          {route}
        </h5>
      </Link>
    )
  }

  const handleClickedBilling = () => dispatch(displayEnableBillingModalAction())

  const handleClickedInviteFriends = () => {
    dispatch(setDisplayReferralModalAction(true))
    trackEvent('opened-referral-modal', { source: 'navigation' })
  }

  if (!user) return null

  const billingLink =
    props.billingEnabled || isAdmin(role) ? (
      link(Route.Invoices)
    ) : user!.organization.enable_billing_flow ? (
      <h5 role="button" className="bold text-l hover-primary py-1 m-0 text--gray4" onClick={handleClickedBilling}>
        Billing
      </h5>
    ) : null

  const inviteFriends = (
    <h5
      role="button"
      className={`${
        localStorage.getItem('opened_referral_modal') === 'true' ? '' : 'swing'
      } text-l single-line bold hover-primary py-1 m-0 text--gray4`}
      onClick={handleClickedInviteFriends}
    >
      Invite Friends
    </h5>
  )

  return (
    <div
      onClick={() => {
        if (!navOpen) dispatch(setNavOpenAction(true))
      }}
      className={`min-vh-100 z-max-3 transition-f ${navOpen ? 'min-width-200px' : 'min-width-50px pointer'}`}
    >
      <div className={`position-fixed left-0 width-100 top-0 transition-f ${navOpen ? 'min-width-200px' : 'min-width-50px'}`}>
        <img
          style={{ marginRight: '-19.5px', marginTop: '66px' }}
          className={`
            float-right hover-bg--gray1 transition-m border bg--white rounded rounded-circle icon-m p-2 shadow-sm pointer 
            ${navOpen ? 'border--primary' : 'border--gray2'}`}
          src={expandIcon}
          onClick={() => dispatch(setNavOpenAction(!navOpen))}
        />

        <div
          style={{ paddingTop: '80px', marginLeft: navOpen ? '' : '-200px' }}
          className={`border-right px-3 ${props.dark ? 'bg--black' : 'bg--white'} overflow-none vh-100 transition-f`}
        >
          {/* Cases section */}
          {link(Route.Cases)}

          {/* Practice section */}
          {!isVet(role) && (
            <div>
              <Divider width="30px" />
              {isAdmin(role) && link(Route.Practices)}
              {(isAdmin(role) || (isUser(role) && user.organization.dicom_servers.length > 0)) && link(Route.DicomServers)}
              {billingLink}
              {isAdmin(role) && link(Route.Pricing)}
            </div>
          )}

          {/* Staff section */}
          {!isUser(role) && (
            <div>
              <Divider width="30px" />
              {isAdmin(role) && link(Route.Staff)}
              {isAdmin(role) && link(Route.PayRates)}
              {link(Route.Earnings)}
            </div>
          )}

          {/* AI + sales section */}
          {isSuperAdmin(role) && (
            <div>
              <Divider width="30px" />
              {link(Route.Enterprises)}
              {link(Route.Feed)}
              {link(Route.Metrics)}
              {link(Route.Conditions)}
              {link(Route.Sales)}
            </div>
          )}

          {/* Settings section */}
          <div>
            <Divider width="30px" />
            {link(Route.Settings)}
            {isEnterpriseAdmin(role) && link(Route.Billing)}
            {!isVet(role) && !isEnterpriseAdmin(role) && isRadimalEnterprise && inviteFriends}

            <br />

            <h5 role="button" className="bold text-l single-line hover-primary m-0 gray4" onClick={props.logout}>
              Sign Out
            </h5>
          </div>
        </div>
      </div>
    </div>
  )
}
